<!-- eslint-disable -->
<template>
    <div>
        <!-- transaction Comfirmation -->
        <TransactionRecordPaymentModal :closeModal="() => toggleRecordPaymentModal({ status: false, invoice: null })" />
        <SendReceiptModal :closeModal="() => toggleSendReceiptModal({ status: false, reference: null })" />
        <EditTransactionModal :closeModal="() => toggleEditTransactionModal({ status: false, transaction: transaction })" />
        <template v-if="!transaction">
            <Spinner />
        </template>
        <router-link v-if="back" :to="'#'" @click="$router.back()">
            <div class="ml4">&lt; Back</div>
        </router-link>

        <div class="flex flex-wrap justify-between items-center w-80-l w-90 center mt4">
            <div class=""></div>
            <div class="action-btns">
                <router-link :to="{ name: 'AddTransactionNew' }">
                  <button class="btn2" @click="changeBusinessType(transaction?.type)">Add New Record</button>
                </router-link>
                <button
                    v-if="transaction?.credit && transaction.transactionPoint != 'invoice' && (transaction?.type === 'sales' || transaction?.type === 'purchases')"
                    class="ml2"
                    @click="toggleRecordPaymentModal({ status: true, transaction })"
                >
                    Record Payment
                </button>
                <button class="ml2" @click.prevent="printReceipt">
                    <img :src="require('@/assets/images/printer.svg')" alt="" /> Print
                </button>
                <button
                    class="btn2 ml2"
                    @click.prevent="resendReceipt(transaction.reference)"
                    :disabled="state.resending"
                >
                    <span v-if="state.resending">
                        <font-awesome-icon icon="fas fa-spinner" /> Sending ...
                    </span>
                    <span v-else>
                        <img :src="require('@/assets/images/Reload.svg')" alt="" /> Resend
                    </span>
                </button>
                <router-link v-if="transaction?.type === 'sales'"
                    to="#"
                    class="btn ml2"
                    v-tippy="'Send receipt'"
                    @click.prevent="toggleSendReceiptModal({ status: true, reference: transaction?.reference })">
                    <font-awesome-icon icon="fas fa-envelope" /> Send receipt
                </router-link>
                <router-link
                    to="#"
                    class="btn ml2"
                    v-tippy="'Edit Transaction'"
                    @click.prevent="toggleEditTransactionModal({ status: true, transaction: transaction })"
                >
                    <font-awesome-icon icon="fas fa-edit" style="color: #808392" /> Edit
                </router-link>
            </div>
        </div>
        <div
            id="receiptContainer"
            ref="transactionDetailsRef"
            class="box-border w-80-l w-90 center mt2 mini-spacing"
            v-if="transaction"
        >
            <div class="w-100 flex justify-between">
                <div class="box-border-bottom b f4-l f5 pa3-l pa3" style="color: #132c8c">
                    Transaction Receipt - {{ moment(transaction.date).format('ddd MMM DD, YYYY') }}
                </div>
                <div v-if="prepaid && transaction?.type === 'prepaid_expense'" class="box-border-bottom b f4-l f5 pa3-l pa3">
                    Reference: {{ transaction?.prepaymentData?.[0]?.reference }}
                </div>
                <div v-else class="box-border-bottom b f4-l f5 pa3-l pa3">
                    Reference: {{ transaction?.number || transaction?.reference }}
                </div>
            </div>
            <div class="pa4-l pa2" style="background: #fbfcfe">
                <div class="tc pa4" style="background: white">
                    <div class="flex justify-center items-center" v-if="orgData?.org">
                        <div ref="companyLogoWrapper" v-if="orgImage">
                            <img
                                ref="companyLogo"
                                :src="orgImage"
                                alt=""
                                style="object-position: center; object-fit: cover; width: 70px; height: 70px"
                                class="rounded-circle"
                            />
                        </div>
                        <div
                            v-else
                            class="flex justify-center items-center font-w3"
                            style="
                                background: rgb(215, 222, 250);
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                                font-size: 25px;
                                color: rgb(19, 44, 140);
                            "
                        >
                            <span class="org-initials">{{ orgInitials || userInitials }}</span>
                        </div>
                    </div>
                    <div class="b pv3">{{ orgData?.org }}</div>
                    <div class="pv1" v-if="orgData?.phone">{{ orgData?.phone }}</div>
                    <div class="pv1" v-if="orgData?.email">{{ orgData?.email }}</div>
                    <div class="pv1" v-if="orgData?.address">{{ orgData?.address }}</div>

                    <div class="pt3">
                        <img src="../../../assets/images/Succes-Icons.svg" alt="" />
                        <div class="pv1">Transaction Total</div>
                        <div v-if="prepaid && transaction?.type === 'prepaid_expense'" class="pv2 f4 b">
                            {{ transaction?.prepaymentData?.[0]?.amount }}
                        </div>
                        <div v-else class="pv2 f4 b">{{ transaction.grandTotal }}</div>
                    </div>
                    <!-- $store.state.Settings?.currency -->
                    <div class="font-w1 f5 pt3">
                        Your <span v-if="transaction?.type === 'sales'">Order</span>
                        <span v-else-if="transaction?.type === 'purchases' && transaction?.transactionPoint === 'produce'"
                            >Production</span
                        >
                        <span v-else-if="transaction?.type === 'purchases'">Purchase</span>
                        <span v-else-if="transaction?.type === 'expenses'">Expense</span>
                        <span v-else-if="transaction?.type === 'repayment'">Repayment</span>
                        <span v-else-if="transaction?.subtype === 'capital'">Investment</span>
                        <span v-else-if="transaction?.type === 'wip'">Wip</span>
                        <span
                            v-else-if="
                                transaction.subtype === 'stock_introduced' && transaction.transactionPoint === 'transfer_in'
                            "
                            >Transfer in</span
                        >
                        <span
                            v-else-if="
                                transaction.subtype === 'promotion_stock' && transaction.transactionPoint === 'transfer_out'
                            "
                            >Transfer out</span
                        >
                        <span class="ttc" v-else>{{ transaction.subtype }}</span>
                        <span class="pl1">ID: {{ transaction?._id }}</span>
                    </div>
                    <div v-if="prepaid && transaction?.type === 'prepaid_expense'" class="pv1 mt2">
                        <!-- {{ new Date(transaction?.prepaymentData?.[0]?.date || transaction.createdAt).toUTCString() }} -->
                        Created: {{ moment(transaction?.prepaymentData?.[0]?.date || transaction.createdAt) }}
                    </div>
                    <div v-else class="pv1 mt2">
                        <!-- {{ moment(transaction?.date || transaction.createdAt) }} -->
                        Created: {{ moment(transaction.createdAt) }}
                    </div>
                </div>
                <div style="background: white">
                    <div class="items-warapper pa5-l pa4 font-w1 pb3-l">
                        <div class="flex justify-between pv3 box-border-bottom color-ash">
                            <div>Transaction Type</div>
                            <div v-if="transaction.transactionPoint === 'stock_update'" class="text-capitalize">
                                {{ transaction?.subtype ? transaction?.subtype.split('_').join(' ').toString() : '' }}
                            </div>
                            <div
                                v-else-if="
                                    transaction.subtype === 'stock_introduced' && transaction.transactionPoint === 'transfer_in'
                                "
                            >
                                Transfer In
                            </div>
                            <div
                                v-else-if="
                                    transaction.subtype === 'promotion_stock' && transaction.transactionPoint === 'transfer_out'
                                "
                            >
                                Transfer Out
                            </div>
                            <div v-else-if="transaction.transactionPoint === 'produce'">
                                Produce
                            </div>
                            <div
                                v-else-if="
                                    transaction?.type === 'inflows' &&
                                    transaction?.subtype === 'stock_introduced' &&
                                    transaction?.transactionPoint === 'direct'
                                "
                            >
                                {{ transaction?.subtype ? transaction?.subtype.split('_').join(' ').toString() : '' }}
                            </div>
                            <div v-else-if="transaction?.type === 'inflows'">Interest Income</div>
                            <div v-else style="text-transform: capitalize">{{ transaction.type }}</div>
                        </div>

                        <div v-for="item of transaction?.items" v-bind:key="item._id">
                            <div class="flex justify-between pv3 box-border-bottom ttc" style="border-top: 1px solid #e3e8ee">
                                <div>
                                    <div>
                                        {{
                                            item?.productName || item?.name
                                                ? `${item.productName || item.name} (${item.quantity})`
                                                : item.subtype
                                        }}
                                    </div>
                                    <div v-if="item.notes || item.note" class="text-muted">{{ item.notes || item.note }}</div>
                                </div>
                                <div>
                                    <div v-if="item?.hasDiscount || item?.discount">
                                        <span class="text-muted" style="text-decoration: line-through">{{ item.amount }}</span
                                        >&nbsp;
                                        <span>{{ item.total }}</span>
                                    </div>
                                    <div v-else>{{ item.amount }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="transaction.transactionPoint === 'produce'" class="pv2 box-border-bottom f5">
                          {{ transaction.output.description }}
                        </div>

                        <!-- Sub Total-->
                        <div v-if="transaction?.type === 'expenses' || transaction?.type === 'prepaid_expense'">
                            <div class="flex justify-between pv3 box-border-bottom color-ash">
                                <div>Subtype</div>
                                <div>
                                    {{ transaction?.subtype.split('_').join(' ').toString() }}
                                </div>
                            </div>
                            <div class="flex justify-between pv3 box-border-bottom color-ash">
                                <div>Expense type</div>
                                <div>{{ transaction?.expense_type }}</div>
                            </div>
                            <div class="flex justify-between pv3 box-border-bottom color-ash">
                                <div v-if="transaction?.description">Description</div>
                                <div v-if="transaction?.description">{{ transaction?.description }}</div>
                            </div>
                            <div
                                v-if="transaction?.type === 'prepaid_expense'"
                                class="flex justify-between pv3 box-border-bottom color-ash"
                            >
                                <div>Duration</div>
                                <div>{{ transaction?.duration }} Months</div>
                            </div>
                            <div
                                v-if="transaction?.type === 'prepaid_expense'"
                                class="flex justify-between pv3 box-border-bottom color-ash"
                            >
                                <div>Monthly Prepayment</div>
                                <div>{{ formatAmount(transaction?.monthly_repayment, $store.state.Settings?.currency) }}</div>
                            </div>
                        </div>
                        <div
                            v-else-if="transaction?.type === 'inflows' || transaction?.type === 'outflows'"
                            class="flex justify-between pv3 box-border-bottom color-ash"
                        >
                            <div v-if="transaction?.description">Description</div>
                            <div v-if="transaction?.description">{{ transaction?.description }}</div>
                        </div>

                        <div v-else-if="(transaction?.type === 'sales' || transaction?.type === 'purchases') && transaction?.description">
                            <div class="flex justify-between pv3 box-border-bottom color-ash">
                                <div v-if="transaction?.description">Description</div>
                                <div v-if="transaction?.description">{{ transaction?.description }}</div>
                            </div>
                            <div class="flex justify-between pv3 box-border-bottom color-ash">
                                <div>Subtotal</div>
                                <div>{{ transaction?.subtotal }}</div>
                            </div>
                        </div>

                        <div v-else class="flex justify-between pv3 box-border-bottom color-ash">
                            <div>Subtotal</div>
                            <div>{{ transaction?.subtotal }}</div>
                        </div>

                        <!-- Charges-->
                        <div v-for="item of transaction?.charges" v-bind:key="item._id">
                            <div class="flex justify-between pv3 box-border-bottom color-ash">
                                <!-- <div class="ttc">{{ item?.subtype }}</div>-->
                                <div class="ttc">
                                    <span class="pr1">{{ item?.subtype }}</span>

                                    <span v-if="item?.basedOn === 'percent'"> ({{ item?.value }} %) </span>
                                </div>
                                <div>{{ item?.amount }}</div>
                            </div>
                        </div>

                        <div class="flex justify-between pv3 box-border-bottom f5 b">
                            <div>Grand Total</div>
                            <div>{{ transaction.grandTotal }}</div>
                        </div>

                        <div class="flex justify-between pv3 box-border-bottom f5">
                            <div class="dark-green">Amount Paid</div>
                            <div v-if="prepaid && transaction?.type === 'prepaid_expense'" class="dark-green">
                                {{ transaction?.prepaymentData?.[0]?.amount }}
                            </div>
                            <div v-else class="dark-green">{{ transaction?.paid }}</div>
                        </div>

                        <div v-if="transaction?.credit" class="flex justify-between pv3 box-border-bottom f5">
                            <div class="dark-red">Amount Owed</div>
                            <div class="dark-red">{{ transaction?.owed }}</div>
                        </div>

                        <div v-if="transaction?.due_date" class="flex justify-between pv3 box-border-bottom f5">
                            <div>Due Date</div>
                            <div>{{ moment(transaction.due_date).format('ddd MMM DD, YYYY') }}</div>
                        </div>

                        <div v-if="transaction?.type === 'purchases' && transaction?.transactionPoint === 'produce'">
                            <div class="pt3 pb2 b">Raw Materials</div>
                            <div
                                v-for="(data, index) in transaction.input.rawMaterials"
                                :key="index"
                                class="flex justify-between pv2 box-border-bottom f5"
                            >
                                <div>{{ data.name }} ({{ data.quantity }})</div>
                                <div>{{ data.amount }}</div>
                            </div>
                            <div class="pv2 box-border-bottom f5">{{ transaction.input.description }}</div>

                            <div class="pt3 pb2 b">Expenses</div>
                            <div
                                v-for="(data, index) in transaction.expenses.items"
                                :key="index"
                                class="flex justify-between pv2 box-border-bottom f5"
                            >
                                <div class="text-capitalize">
                                    {{ data.name.split('_').join(' ').toString() }}
                                    <span v-if="data?.description && data?.description !== ''">({{ data?.description }})</span>
                                </div>
                                <div>{{ data.amount }}</div>
                            </div>
                        </div>

                        <div class="flex items-center justify-between pv3 box-border-bottom" v-if="transaction?.address1">
                            <div>Shipping</div>
                            <div class="w-50-l w-70 tr lh-copy">{{ transaction?.address1 }}</div>
                        </div>
                        <div class="flex items-center justify-between pv3 box-border-bottom" v-if="transaction?.entityData">
                            <div v-if="transaction?.type === 'wip'">Receiving Staff</div>
                            <div v-else>Billed to</div>
                            <div v-if="transaction?.type === 'wip'" class="w-50-l w-70 tr">
                                <div class="font-w2 pb1">{{ transaction.receivingStaffName }}</div>
                            </div>
                            <div v-else class="w-50-l w-70 tr">
                                <div class="font-w2 pb1">
                                    {{ transaction?.entityData?.name }}
                                    <span v-if="transaction?.entityData?.company">
                                        ({{ transaction?.entityData?.company }})
                                    </span>
                                </div>
                                <div class="lh-copy">
                                    {{ transaction?.entityData?.address }}
                                </div>
                                <div>{{ transaction?.entityData?.phone }}</div>
                            </div>
                        </div>
                        <div class="flex items-center justify-between pv3 box-border-bottom" v-if="transaction?.type === 'wip'">
                            <div>Approval Status</div>
                            <div class="w-50-l w-70 tr">
                                <div class="font-w2 pb1">
                                    {{ transaction.approvalStatus }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="transaction?.paymentInfo" class="pv2 pl5-l pr5-l pl2 pr2">
                        <div class="flex justify-between box-border-bottom pv3">
                            <div class="b">Previous Payment(s)</div>
                        </div>
                        <div
                            v-for="(data, index) of transaction.paymentInfo"
                            v-bind:key="index"
                            class="pv3"
                            style="border-bottom: 1px solid #e3e8ee"
                        >
                            <div class="flex justify-between">
                                <div class="w-30">{{ data.account }}</div>
                                <div class="w-50 b">{{ formatAmount(data.amount, $store.state.Settings?.currency) }}</div>
                                <div class="w-20 b">{{ data?.date ? formatDate(data.date, 'DD/MM/YYYY') : '' }}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="transaction?.glAccounts && transaction?.glAccounts?.length"
                        class="flex items-center pv2 pl5-l pr5-l pl2 pr2"
                    >
                        <span
                            v-if="transaction?.credit && (transaction?.type === 'purchases' || transaction?.type === 'sales')"
                            class="mr1 b"
                            >Please Pay To:
                        </span>
                        <span v-else class="mr1 b">Paid To:</span>
                    </div>
                    <!-- column table -->
                    <div
                        class="flex flex-column pa2-l pa2 pl5-l pr5-l pl2 pr2"
                        v-for="account of transaction.glAccounts"
                        v-bind:key="account"
                    >
                        <div class="flex justify-between pv2 box-border-bottom">
                            <div style="text-transform: capitalize">{{ account.glAccount }}</div>
                            <div>{{ formatAmount(account?.glAmount, $store.state.Settings?.currency) }}</div>
                        </div>
                    </div>
                    <div v-if="transaction?.credit && transaction?.owed_value > 0" class="pl5-l pr5-l pt3">
                        <div class="flex items-center pb4 pv2">
                            <span class="mr1 b">Please Pay To: </span>
                        </div>
                        <div class="flex items-center pv2">
                            <span class="mr1 b">Account Name: </span>
                            <span class="ttc">{{ orgData?.bankAccountName }}</span>
                        </div>
                        <div class="flex items-center pv2">
                            <span class="mr1 b">Account No: </span>
                            <span class="ttc">{{ orgData?.bankAccountNumber }}</span>
                        </div>
                        <div class="flex items-center pv2">
                            <span class="mr1 b">Bank Name: </span>
                            <span class="ttc">{{ orgData?.bankName }}</span>
                        </div>
                        <!-- <div class="flex items-center pv4 box-border-bottom">
                            <span class="mr1">Sort Code: </span>
                            <span class="ttc">{{ state?.sortCode }}</span>
                        </div> -->
                    </div>

                    <div
                        v-if="transaction?.notes"
                        class="flex flex-column items-center justify-center pv3 box-border-bottom"
                    >
                        <div class="tc b">Notes:</div>
                        <div class="tc">{{ transaction?.notes }}</div>
                    </div>
                    <div class="flex items-center justify-center pv3 box-border-bottom">
                        <span class="mr1">Staff: </span>
                        <span class="ttc">{{ transaction?.staffName }}</span>
                    </div>
                    <div v-if="state?.footnote" v-html="state.footnote" class="footnote ph4-l tc"></div>
                    <div class="flex items-center justify-center w-50-l w-80 center pb3 pt3">
                        <div class="b pr2" style="color: #132c8c">Powered by:</div>
                        <img src="../../../assets/images/simple-logo-mobile.svg" alt="" />
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap justify-between items-center" style="border-top: 1px solid #e3e8ee">
                <div class="action-btns ph3-l ph3 pv3 w-70">
                    <font-awesome-icon v-if="state.downloading" icon="fas fa-spinner" />
                    <a
                        v-else
                        href="#"
                        @click.prevent="
                            downloadReceipt({ reference: transaction?.reference, orgId: orgData.orgId, userId: orgData.userId })
                        "
                    >
                        <img src="../../../assets/images/direct-download.svg" alt="" />
                    </a>
                    <a :href="'whatsapp://send?text=' + shareUrl" data-action="share/whatsapp/share" class="share-btn-mobile">
                        <img src="../../../assets/images/share.svg" class="ph3" alt="" />
                    </a>
                    <a href="" class="share-btn-desktop" @click.prevent="copyShareLink(shareUrl)">
                        <img src="../../../assets/images/share.svg" class="ph3" alt="" />
                    </a>
                    <a href="#" @click.prevent="printReceipt"><img src="../../../assets/images/printer.svg" class="pr3" alt="" /></a>
                    <a v-if="transaction?.type === 'sales'" href="#" @click.prevent="openWhatsapp(transaction, shareUrl)"><img src="../../../assets/images/whatsapp.svg" class="" width="15" alt="" /></a>
                </div>

                <div v-if="transaction?.receiptUrl" class="tr ph3-l ph3 pv3 w-30">
                    <!-- <a href="#" @click.prevent="getExpenseReceipt(transaction?.reference)">Attached Receipt</a> -->
                    <a :href="transaction?.receiptUrl" download target="_blank">Attached Receipt</a>
                </div>

                <template v-if="editable">
                    <div class="ph5-l ph4 pv4-l pb0-l pb4">
                        <button class="btn2">Edt</button>
                        <button class="ml2">Confirm</button>
                    </div>
                </template>

                <template v-else>
                    <div></div>
                </template>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
/* eslint-disable */
import { computed, reactive, ref, onMounted, watch } from 'vue'
import { copyText } from 'vue3-clipboard'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { jsPDF } from 'jspdf'
import moment from 'moment'
import { formatAmount, formatDate } from '@/utils/lib'
import Spinner from '@/components/Spinner'
import TransactionRecordPaymentModal from './TransactionRecordPaymentModal'
import SendReceiptModal from './SendReceiptModal'
import EditTransactionModal from './EditTransactionModal'

export default {
    name: 'TransactionDetails',
    props: {
        transaction: Object,
        title: String,
        edit: Function,
        confirm: Function,
        editable: Boolean,
        back: Boolean,
    },
    components: { Spinner, TransactionRecordPaymentModal, SendReceiptModal, EditTransactionModal },
    setup(props) {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const { prepaid } = route.query
        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)
        const userData = computed(() => store.state?.Settings?.organization)
        const date = ref('')
        const formattedDate = computed(() => date.value.toLocaleDateString() + ' ' + date.value.toLocaleTimeString())


        const shareUrl = computed(
            () =>
                `${process.env.VUE_APP_BASE_URL}/${store?.state?.Auth?.userData?.orgId}/${store?.state?.Auth?.userData?.userId}/transaction/${props.transaction?.reference}/receipt`
        )

        watch(props.transaction),
            (newVal) => {
                // console.log(newVal, 'transaction')
            }

        const state = reactive({
            newPDF: null,
            canvas: null,
            height: null,
            bankName: '',
            bankAccountName: '',
            bankAccountNumber: '',
            sortCode: '',
            downloading: false,
            footnote: ''
        })

        const pdf = new jsPDF('p', 'mm', 'a4')
        pdf.setFontSize(40)

        const copyShareLink = (url) => {
            copyText(url, undefined, (error, event) => {
                if (error) {
                    store.dispatch('Alert/setAlert', {
                        message: `Could not copy receipt link`,
                        status: false,
                    })
                } else {
                    store.dispatch('Alert/setAlert', {
                        message: `Receipt link copied`,
                        status: true,
                    })
                }
            })
        }

        const downloadReceipt = async (data) => {
            state.downloading = true
            store.dispatch('Transaction/getTransactionReceiptPdf', data).then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const date = formatDate(new Date(), 'DDMMYYYY')

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org} - ${date}`
                fileLink.setAttribute('download', `transaction-${data.reference} - ${fileName}.pdf`)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                state.downloading = false
            })
        }

        const getExpenseReceipt = (reference) => {
            store.dispatch('Transaction/getExpenseReceipt', reference).then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = response.headers['content-disposition'].substring(22, 52)
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
            })
        }

        const openWhatsapp = (receipt, url) => {
            const phoneNumber = receipt.entityData.phone.replace(/\D/g, '').slice(-10);
            const message =  `
Hi ${receipt.entityName.split(' ')[0]},
Your sales receipt ${receipt.reference} for the sum of ${receipt.paid} on ${receipt.date} from ${receipt.companyName} is available. Check it out: ${url}
Need assistance? Reach us at ${receipt.organization.email} or ${receipt.organization.phone}.
Thanks for choosing ${receipt.companyName}.
Best regards, ${receipt.companyName}`
            const encodedMessage = encodeURIComponent(message).replace(/%0A/g, '%0A%0A')
            const whatsappURL = `https://wa.me/+234${phoneNumber}?text=${encodedMessage}`
            window.open(whatsappURL, '_blank')
        }

        watch(
            () => userData.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal && newVal?.bankDetails) {
                    const bankDetails = newVal?.bankDetails.find((bank) => bank.isDefault === 'true' || bank.isDefault === true)
                    state.bankName = bankDetails?.bankName
                    state.bankAccountNumber = bankDetails?.bankAccountNumber
                    state.bankAccountName = bankDetails?.bankAccountName
                    state.sortCode = bankDetails?.sortCode
                }
            }
        )

        const changeBusinessType = (type) => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        const toggleRecordPaymentModal = (payload) => {
            store.dispatch('Transaction/showRecordPaymentModal', payload)
        }

        const resendReceipt = (reference) => {
            state.resending = true
            store.dispatch('Transaction/resendReceipt', reference).finally(() => {
                state.resending = false
            })
       }

        const toggleSendReceiptModal = (payload) => {
            store.dispatch('Transaction/toggleSendReceiptModal', { ...payload })
        }

        const toggleEditTransactionModal = (payload) => {
            store.dispatch('Transaction/toggleEditTransactionModal', { ...payload })
        }

        onMounted(() => {
            store.dispatch('Settings/getOrganization')
            store.dispatch('Settings/getBankAccounts')
            store.dispatch('Settings/getAccountSettings').then(data => {
                if (data.status) {
                    state.footnote = data?.data?.footnote || ''
                }
            })
        })

        return {
            state,
            prepaid,
            orgData,
            orgInitials,
            userInitials,
            orgImage,
            shareUrl,
            copyShareLink,
            getExpenseReceipt,
            downloadReceipt,
            formatDate,
            formatAmount,
            changeBusinessType,
            date,
            formattedDate,
            moment,
            toggleRecordPaymentModal,
            openWhatsapp,
            resendReceipt,
            toggleSendReceiptModal,
            toggleEditTransactionModal,
        }
    },

    methods: {
        printReceipt() {
            this.$htmlToPaper('receiptContainer')
        },
    },
}
</script>

<style>
.share-btn-mobile {
    display: none;
}

.share-btn-desktop {
    display: inline;
}

@media screen and (max-width: 1261px) {
    .share-btn-mobile {
        display: inline;
    }

    .share-btn-desktop {
        display: none;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.fa-spinner {
    animation: spinner 1s linear infinite;
}
</style>
